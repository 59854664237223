import {MenuCreateOptionInterface} from "kernel/types/module";

class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN', 'ROLE_MEMBER']),
          label: this.vm.$t('page.member'),
          icon: 'fa fa-user-circle',
          route: {
            name: 'member',
          },
        },
      ],
    }
  }
}

export default () => (new menu()).get()
